import { DomainsEnum } from 'common/globalTypes/enums/http';

export const EnvConfig: Record<DomainsEnum, string> = {
  [DomainsEnum.appStoreBackend]:
    process.env.NEXT_PUBLIC_APP_STORE_BACKEND || '',
  [DomainsEnum.accountsBackend]: process.env.NEXT_PUBLIC_ACCOUNTS_BACKEND || '',
  [DomainsEnum.accountsClient]: process.env.NEXT_PUBLIC_ACCOUNTS_CLIENT || '',
  [DomainsEnum.appStoreClient]: process.env.NEXT_PUBLIC_APP_STORE_CLIENT || '',
  [DomainsEnum.dashboardClient]: process.env.NEXT_PUBLIC_DASHBOARD_CLIENT || '',
  [DomainsEnum.myBrandBooks]:
    process.env.NEXT_PUBLIC_MY_BRAND_BOOK_CLIENT || '',
  [DomainsEnum.canonicalURL]: process.env.NEXT_PUBLIC_CANONICAL_URL || '',
  [DomainsEnum.storageUrl]: process.env.NEXT_PUBLIC_STORAGE_URL || '',
  [DomainsEnum.metaDataBaseUrl]:
    process.env.NEXT_PUBLIC_META_DATA_BASE_URL || '',
  [DomainsEnum.rudderStackKey]:
    process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY || '',
  [DomainsEnum.rudderStackPlaneUrl]:
    process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL || '',
  [DomainsEnum.hooryWebsiteToken]:
    process.env.NEXT_PUBLIC_HOORY_APP_WEBSITE_TOKEN || ''
};
